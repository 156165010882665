import React from "react";
import {Modal,Card,CardBody,Col,Button} from "reactstrap";
//import Chart from 'react-apexcharts'
import reduxHelper from "./../../../helper/index.js";
import { connect } from 'react-redux';
import moment from 'moment';
import MultiSelect from "@khanacademy/react-multi-select";
import DesempenhoNoQuiz from './../chartlist/Desempenho_quiz.jsx';
import TempoInvestido from './../chartlist/Tempo_investido.jsx';
import TempoAtividade from './../chartlist/Tempo_atividade.jsx';
import TempoUnit from './../chartlist/Tempo_unit.jsx';
import NotaQuizUnit from './../chartlist/Nota_quiz_unit.jsx';
import Abaco from "./Abaco.jsx";
import RelatorioUnit from "./RelatorioUnit.jsx";
import api from "./../../../Api.js";

//import apis from "../../../Api.js";

import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';

class ModalBasicChartUser extends React.Component { 

	constructor(props){

        super(props);

        this.setStateTop = this.props.setState;
        this.helper = new reduxHelper(this.props);

        this.getDataChart1 = this.getDataChart1.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.saveChartSetting = this.saveChartSetting.bind(this);

        this.state = {

            series1:[],
            series2:[],
            label2:[],
            selected: this.props.user.preference.modalbasicchartuser

        };

        window.chartuser2 = this;

    }

    
      
    async componentDidUpdate(){

        if(this.state.idUser !== this.props.idUser){

            await this.setState({idUser:this.props.idUser});
            //this.getDataChart1();
            this.getDataChart2();

        }

    }

    handleKeyDown(event){ 
        
        if(this.props.state.openModalChartUser !== true){ return false; }
        if(event.key === "ArrowLeft"){ this.props.previousUserChart(); }
        if(event.key === "ArrowRight"){ this.props.nextUserChart(); }

    }

    componentDidMount(){ 
            
        document.addEventListener("keydown", this.handleKeyDown); 
    
    }

    componentWillUnmount(){ 
        
        document.removeEventListener("keydown", this.handleKeyDown); 
    
    }

 
    chart1(){
            
        return {labels: [this.props.translate['acertou_na_primeira'],this.props.translate['acertou_na_segunda'],this.props.translate['acertou_na_terceira'],this.props.translate['acertou_na_quarta']],
            responsive:[{
                breakpoint:480,
                options:{
                    chart:{width:10},
                    legend:{position: 'bottom'}
                }
            }],
            legend:{horizontalAlign:'right'},

            chart: {
                toolbar: {
                  show: true, 
                },
            }
    
            
        };
        
    }

    chart2(){

        return{
            stroke:{width:[0,4]}, 
            labels:this.state.label2,
            yaxis:[{title:{text: this.props.translate['tempo_em_minutos']}}]
        };

    }

    async getDataChart1(){

        var data = await this.helper.chart().chartQuizperFormance(this.props.idUser);

        if(data !== false){

            if(data.status === 200){

                this.setState({series1:data.data});
                console.log(data.data);
                return data.data;

            }

        }

        return [];
        
    }

    //Para salvar os settings do
    async saveChartSetting(data){
        
        console.log({modalbasicchartuser:data});

        var save = await this.helper.preference().savePreference({modalbasicchartuser:JSON.stringify(data)});
        this.props.dispatch({type:"SET_DATA_SET_PREFERENCE_MODAL_BASIC_CHART_USER",data:data});
        console.log(save);

    }


    async getDataChart2(){

        var data = await this.helper.chart().chartTimeInvested(this.props.idUser);

        if(data !== false){

            if(data.status === 200){

                for(let index = 0; index < data.data.label.length; index++){

                    data.data.label[index] = moment(data.data.label[index],'YYYY-MM-DD').format('DD/MM/YYYY')
                    
                }

                var series2 = [{
                    name: this.props.translate['em_aula'],
                    type: 'column',
                    data: data.data.minutes
                }];
                
                this.setState({label2:data.data.label,series2:series2});
                return data.data;

            } 
        }  
    }

    

  	render(){

        const {selected} = this.state;
        const optionsChart = [
            {label: this.props.translate['desempenho_no_quiz'], value: "desempenho_no_quiz"},
            {label: this.props.translate['tempo_investido'], value: "tempo_investido"},

            {label: this.props.translate['tempo_na_atividade'], value: "tempo_atividade"},
            {label: this.props.translate['tempo_na_unit'], value: "tempo_unit"},
            {label: this.props.translate['quizzes_da_unit'], value: "chartQuizUserUnit"},

            

        ];
          
  		return(
        <React.Fragment>
            <Modal className="modal-dialog-centered fastShow dialogfastuserchart" toggle={() => {
                
                this.setStateTop({openModalChartUser:false});
                this.setState({modalAbaco:false});

            } } style={{maxWidth:'1000px'}} isOpen={this.props.state.openModalChartUser} >
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                    <div className="modal-header">
                        <h6 className="modal-title" id="modal-title-default" style={{margin:"auto"}}><img alt="..." style={{height:'45px',width:'45px',marginRight:'6px',boxShadow:'0px 2px 3px -1px #000',borderRadius:'26px'}} src={this.props.photoUserChart} />{this.props.name}</h6>
                    </div>

                    <div className="select_charts_fast">

                        <button onClick={() => {

                            this.setState({modalAbaco:true}); 

                        }} className="btn btn-primary" style={{position:'absolute',right:'15px',top:'22px'}}> 
                            <img src="/images/2612073.png" style={{height:'60px',marginRight:'10px',position:'absolute',top:'-9px',right:'147px'}} alt=".."/> 
                            Relatório Ábaco
                        </button>

                        <button onClick={() => {

                            this.setState({modalUnit:true,modalUnitunit:this.props.unit}); 

                        }} className="btn btn-primary" style={{position:'absolute',right:'15px',top:'84px'}}> 
                            <img src="https://iconutopia.com/wp-content/uploads/2016/06/rocket-book.png" style={{height:'60px',marginRight:'10px',position:'absolute',top:'-9px',right:'147px'}} alt=".."/> 
                            Relatório Unit
                        </button>


                        <MultiSelect  
                        
                            overrideStrings={{
                                selectSomeItems: this.props.translate['selecione_alguns_graficos'],
                                allItemsAreSelected: this.props.translate['todos_selecionados'],
                                selectAll: this.props.translate['selecionar_todos'],
                                search: this.props.translate['procurar'],
                            }}
                            
                            options={optionsChart} selected={selected} onSelectedChanged={selected => { this.saveChartSetting(selected); this.setState({selected}); } }
                            
                        />

                    </div>
                    
                      
                    <CardBody className="" >

                        <i onClick={ () => this.props.previousUserChart() } style={{left:'10px',zIndex:"9999",cursor:"pointer",fontSize:'22px',position:'absolute',top:'calc(50% - 10px)'}} className="hvr-grow fas fa-chevron-left"></i>
                        <i onClick={ () => this.props.nextUserChart() } style={{zIndex:"9999",cursor:"pointer",left:'calc(100% - 25px)',fontSize:'22px',position:'absolute',top:'calc(50% - 10px)'}} className="hvr-grow fas fa-chevron-right"></i>

                        <div className="row">

                        
                            {

                                (this.props.user.preference.modalbasicchartuser.indexOf('desempenho_no_quiz') !== -1) && 
                                <Col md="6">
                                    <center style={{fontSize:'15px',marginBottom:'10px',fontWeight:'600'}}>{this.props.translate['desempenho_no_quiz']}</center>
                                    <DesempenhoNoQuiz idUser={this.props.idUser} />
                                </Col>

                            }

                            {

                               (this.props.user.preference.modalbasicchartuser.indexOf('tempo_investido') !== -1) && 
                                <Col md="6">
                                    <center style={{fontSize:'15px',marginBottom:'10px',fontWeight:'600'}}>{this.props.translate['tempo_investido']}</center>
                                    <TempoInvestido idUser={this.props.idUser}/>
                                </Col>

                            }

                            {

                                (this.props.user.preference.modalbasicchartuser.indexOf('tempo_atividade') !== -1) && 
                                <Col className="chartCol12" md="12" style={{height:"260px"}}>
                                    <center style={{fontSize:'15px',marginBottom:'10px',fontWeight:'600'}}>{this.props.translate['tempo_na_atividade']}</center>
                                    <TempoAtividade height="260px" unit={this.props.unit} classroom={this.props.classroom} idUser={this.props.idUser} />
                                </Col>

                            }

                            {

                                (this.props.user.preference.modalbasicchartuser.indexOf('tempo_unit') !== -1) && 
                                <Col className="chartCol12" md="12" style={{height:"260px"}}>
                                    <center style={{fontSize:'15px',marginBottom:'10px',fontWeight:'600'}}>{this.props.translate['tempo_na_unit']}</center>
                                    <TempoUnit height="260px" unit={this.props.unit} classroom={this.props.classroom} idUser={this.props.idUser}/>
                                </Col>

                            }

                            {

                                (this.props.user.preference.modalbasicchartuser.indexOf('chartQuizUserUnit') !== -1) && 
                                <Col className="chartCol12 quiz_chart_aa" md="12" style={{height:"280"}}>
                                    <style>{'  .quiz_chart_aa .apexcharts-legend{ right: -80px !important; } '}</style>
                                    <center style={{fontSize:'15px',marginBottom:'10px',fontWeight:'600'}}>{this.props.translate['quizzes_da_unit']}</center>
                                    <NotaQuizUnit height="260px" unit={this.props.unit} classroom={this.props.classroom} idUser={this.props.idUser}/>
                                </Col>

                            }
                            

                        </div>
                        <Col md="12">
                            <center>

                                <Button color="secondary" size="sm" type="button" onClick={() => { this.setStateTop({openModalChartUser:false}); } }><i className="fas fa-times"></i> Fechar</Button>
                                <Button hidden color="secondary" size="sm" type="button"><i className="far fa-address-card"></i> Acessar perfil completo</Button>

                            </center>
                        </Col>

                    </CardBody>
                    </Card>
                </div>
            </Modal>


            {

                (this.state.modalAbaco === true) &&
                <Modal className="modal-dialog-centered fastShow dialogfastuserchart" toggle={() => { this.setState({modalAbaco:false}); }} style={{maxWidth:'1600px'}} isOpen={this.props.state.openModalChartUser} >
                    <div className="modal-body p-0">
                        <Card className="bg-secondary border-0 mb-0">
                            <div className="modal-header">
                                <h6 className="modal-title" id="modal-title-default" style={{margin:"auto"}}>
                                    Relatório Ábaco
                                </h6>

                                <button style={{position:'absolute',right:'266px'}} className="btn btn-danger" onClick={() => { 
                                    
                                    const doc = new jsPDF();
                                    autoTable(doc, { html: '#my-table' });

                                    console.log('this.props.name');
                                    console.log(this.props.name);

                                    doc.save(this.props.name+".pdf");
                                    
                                }}><i class="fa fa-file-pdf-o" aria-hidden="true"></i> Baixar PDF</button>
                                <button style={{position:'absolute',right:'110px'}} className="btn btn-success" onClick={() => { 
                                    
                                    window.abaco.geraXlsx();

                                }}>Baixar Planilha</button>

                                <button style={{position:'absolute',right:'20px'}} className="btn btn-secondary" onClick={() => { this.setState({modalAbaco:false}); }}>Fechar</button>
                            </div>
                            <CardBody className="" id="abacoddsa">
                                <Abaco name={this.props.name} userId={this.props.idUser}/>
                            </CardBody>
                        </Card>
                    </div>
                </Modal>

            }

            {

                (this.state.modalUnit === true) &&
                <Modal className="modal-dialog-centered fastShow dialogfastuserchart" toggle={() => { this.setState({modalUnit:false}); }} style={{maxWidth:'600px'}} isOpen={true} >
                    <div className="modal-body p-0">
                        <Card className="bg-secondary border-0 mb-0">
                            <div className="modal-header">
                                <div style={{display:'flex',alignItems:'center'}}>
                                    <div style={{display:'flex',alignItems:'center',gap:'10px'}}>
                                        <h6 className="modal-title" id="modal-title-default" style={{margin:"auto"}}>Relatório da Unit</h6>

                                        <button onClick={() => {

                                            api.report_unit_xlsx(this.props.classroom,this.state.modalUnitunit,this.props.idUser).then((response) => {
                                                                                    
                                                window.open(response.data.url);

                                            }).catch((error) => {

                                                console.log('erro');
                                                console.error(error);
                                                window.swal.fire('Ops!','Não foi possível carregar o relatório.','error');

                                            });

                                        }} className="btn btn-sm btn-success"><i className="far fa-file-excel"></i> Exportar planilha</button>
                                    </div>
                                    <div style={{marginLeft:'10px'}} >
                                        <select style={{padding:'0px',height:'23px'}} value={this.state.modalUnitunit} onChange={(event) => {

                                            this.setState({modalUnitunit:event.target.value});

                                        }} className="form-control">
                                            <option></option>
                                            {

                                                this.props.state.units.map((unit) => {
                                                    
                                                    return <option value={unit.id}>{unit.name}</option>

                                                })

                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <CardBody className="" id="abacoddsa">
                                <RelatorioUnit classroom={this.props.classroom} name={this.props.name} userId={this.props.idUser} unit={this.state.modalUnitunit} />
                            </CardBody>
                        </Card>
                    </div>
                </Modal>

            }

  		</React.Fragment>);

  	}


}


export default connect(state =>({

	languages: state.language,
	translate: state.translate,
	accesslevel: state.accesslevel,
	permission: state.permission,
	user: state.user,
  
}))(ModalBasicChartUser);