import React from "react";
import {Modal,Card,CardBody,Col,Button,UncontrolledTooltip} from "reactstrap";
import Abaco from "./Abaco.jsx";
import {ClipLoader} from "react-spinners";
import { connect } from 'react-redux';
import api from "./../../../Api.js";
import ReactStars from "react-rating-stars-component";

class RelatorioUnit extends React.Component{

    constructor(props){

        super(props);
        this.state = {
            unit:props.unit,
            data:{},
            load:true,
            evaluations:[],
            evaluations_user:[]
        };

        this.getListEvaluatins = this.getListEvaluatins.bind(this);
        this.evaluationlist_unit_user = this.evaluationlist_unit_user.bind(this);

        window.relatoriounit = this;

    }

    reportUnit(){

        api.report_unit(this.props.classroom,this.props.unit,this.props.userId).then((response)=>{

            this.setState({load:true,data:response.data});

        }).catch((error)=>{

            console.log('erro');
            console.error(error);
            window.swal.fire('Ops!','Não foi possível carregar o relatório.','error');

        });

    }

    getListEvaluatins(){

        api.evaluationlist_unit().then((response)=> {

            this.setState({load2:true,evaluations:response.data});

        }).catch((error) => {
            
            console.log('erro');
            console.error(error);
            window.swal.fire('Ops!','Não foi possível carregar a lista de avaliação','error');


        });

    }

    evaluationlist_unit_user(){

        api.evaluationlist_unit_user(this.props.classroom,this.props.unit,this.props.userId).then((response)=> {

            this.setState({load3:true,evaluations_user:response.data});

        }).catch((error) => {

            console.log('erro');
            console.error(error);
            window.swal.fire('Ops!','Não foi possível carregar a lista de avaliação dos usuários','error');

        });

    }

    componentDidMount(){

        this.reportUnit();
        this.getListEvaluatins();
        this.evaluationlist_unit_user();

    }

    componentDidUpdate(){

        if(this.props.unit !== this.state.unit){

            this.setState({unit:this.props.unit,load:false,load3:false});
            this.reportUnit();
            this.evaluationlist_unit_user();

        }

    }

    render (){

    return  <React.Fragment>
            {

                (this.state.load === false) &&
                <center><ClipLoader/></center>

            }
            {

                (this.state.load === true) &&
                <React.Fragment> 
                    <div>
                        <h3>Geral</h3>
                    </div>
                    <div>
                        <table className="align-items-center table-flush table" >
                            <thead className="thead-light">
                                <tr>
                                    <th>Nome</th>
                                    <th><center>Valor</center></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Quantidade de presença</td>
                                    <td><center>{this.state.data.total_presence}</center></td>
                                </tr>
                                <tr>
                                    <td>Quantidade de falta</td>
                                    <td><center>{this.state.data.total_falta}</center></td>
                                </tr>
                                <tr>
                                    <td>Total de tempo na UNIT</td>
                                    <td><center>{this.state.data.unit_total_time}</center></td>
                                </tr>
                                <tr>
                                    <td>Quiz completados</td>
                                    <td><center>{this.state.data.quiz_completados}</center></td>
                                </tr>
                                <tr>
                                    <td>Acertou Quiz de 1º</td>
                                    <td><center>{this.state.data.quiz_1}</center></td>
                                </tr>
                                <tr>
                                    <td>Acertou Quiz de 2º</td>
                                    <td><center>{this.state.data.quiz_2}</center></td>
                                </tr>
                                <tr>
                                    <td>Acertou Quiz de 3º</td>
                                    <td><center>{this.state.data.quiz_3}</center></td>
                                </tr>
                                <tr>
                                    <td>Acertou Quiz de 4º</td>
                                    <td><center>{this.state.data.quiz_4}</center></td>
                                </tr>
                                <tr>
                                    <td>Tempo em quiz</td>
                                    <td><center>{this.state.data.quiz_total_time}</center></td>
                                </tr>
                                <tr>
                                    <td>Média abaco</td>
                                    <td><center>{this.state.data.abaco_media_score}</center></td>
                                </tr>
                                <tr>
                                    <td>Total de tempo no abaco</td>
                                    <td><center>{this.state.data.abaco_total_time}s</center></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style={{marginTop:'12px'}}>
                        <h3>Avaliações</h3>
                    </div>
                    <div>
                        <table className="align-items-center table-flush table" >
                            <thead className="thead-light">
                                <tr>
                                    <th>Nome</th>
                                    <th>Nota</th>
                                </tr>
                            </thead>
                            <tbody>
                                {

                                    (this.state.load3 === true) &&
                                    <React.Fragment>
                                        {

                                            this.state.evaluations.map((evaluation) => {

                                                var df = 0;

                                                if(this.state.load3 === true){

                                                    var find = this.state.evaluations_user.find(x => x.evaluation === evaluation.id);
                                                    if(find){
                                                        df = find.score;
                                                    }

                                                }

                                                var val = {
                                                    size: 20,
                                                    count: 5,
                                                    isHalf: false,
                                                    value: df,
                                                    color: "#959595",
                                                    activeColor: "#3833ff",
                                                    onChange: newValue => {
                                                        
                                                    }
                                                };

                                                return <tr>
                                                    <td>{evaluation.name}</td>
                                                    <td style={{pointerEvents:'none'}}>
                                                        <ReactStars {...val} />
                                                    </td>
                                                </tr>

                                            })

                                        }
                                    </React.Fragment>

                                }
                                
                            </tbody>
                        </table>
                    </div>
                    <div style={{marginTop:'12px'}}>
                        <h3>BNCC</h3>
                    </div>
                    {

                        (this.state.load === true) &&
                        <div>
                            <table className="align-items-center table-flush table" >
                                <thead className="thead-light">
                                    <tr>
                                        <th>Nome</th>
                                        <th>Adquiridos</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Competências</td>
                                        <td>
                                            {

                                                (Array.isArray(this.state.data.competencias) === true) &&
                                                <React.Fragment>
                                                    {
                                                        this.state.data.competencias.map((item,index) => {

                                                            var tooltip = "tp_dd2_"+index;
                                                            return <React.Fragment>
                                                            <div style={{
                                                                width:'360px',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                            }} id={tooltip}>{item}</div>
                                                            <UncontrolledTooltip delay={0} target={tooltip}>{item}</UncontrolledTooltip>
                                                        </React.Fragment>;

                                                        })
                                                    
                                                    }   
                                                </React.Fragment>

                                            }
                                            
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Habilidades</td>
                                        <td>
                                        {
/*


*/
                                            (Array.isArray(this.state.data.habilidades) === true) &&
                                            <React.Fragment>
                                            {
                                                this.state.data.habilidades.map((item,index) => {

                                                    var tooltip = "tp_dd_"+index;
                                                    return <React.Fragment>
                                                        <div style={{
                                                            width:'360px',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }} id={tooltip}>{item}</div>
                                                        <UncontrolledTooltip delay={0} target={tooltip}>{item}</UncontrolledTooltip>
                                                    </React.Fragment>;

                                                })
                                            
                                            }
                                            </React.Fragment>
                                        }
                                        </td>
                                         
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    }
                    

                </React.Fragment>
                 

            }
        </React.Fragment> 

    }

}

export default connect(state =>({

	languages: state.language,
	translate: state.translate,
	accesslevel: state.accesslevel,
	permission: state.permission,
	user: state.user,
  
}))(RelatorioUnit);
